<template>
  <v-container
    fluid
    class="pb-7"
  >
    <v-progress-linear
      v-if="carregando"
      indeterminate
    />

    <validation-observer ref="formTelefones">
      <v-row
        class="mt-1"
      >
        <v-col
          cols="12"
          md="4"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="descricao"
          >
            <v-text-field
              v-model="dados.descricao"
              outlined
              dense
              :disabled="carregando"
              hide-details="auto"
              label="Descrição"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="numero_telefone"
          >
            <v-text-field
              v-model="dados.numero_telefone"
              outlined
              dense
              :disabled="carregando"
              hide-details="auto"
              label="Número"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="ramal"
          >
            <v-text-field
              v-model="dados.ramal"
              outlined
              dense
              :disabled="carregando"
              hide-details="auto"
              label="Ramal"
              :error-messages="errors"
            >
              <template v-slot:append-outer>
                <v-btn
                  icon
                  color="success"
                  @click="salvar"
                >
                  <v-icon>
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col
        cols="12"
      >
        <i
          v-if="Object.keys(telefones).length <= 0 && !carregando"
          class="grey--text"
        >
          Nenhum telefone adicionado
        </i>

        <v-chip
          v-for="(telefone, key) in telefones"
          v-else
          :key="key"
          class="my-1 mr-1"
          close
          label
          close-icon="mdi-delete"
          @click:close="deletar(telefone)"
        >
          <span class="body-1">
            {{ telefone.telefone.descricao || '-' }}
            •
            {{ telefone.telefone.numero_telefone || '-' }}
            •
            {{ telefone.telefone.ramal || '-' }}
          </span>
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import pessoasApi from '@/api/pessoas'

  export default {

    props: {
      pessoaId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        carregando: false,
        telefones: [],
        dados: {},
      }
    },

    watch: {
      pessoaId: {
        immediate: true,
        handler (id) {
          if (id) {
            this.buscar()
          }
        },

      },
    },

    methods: {
      async buscar () {
        try {
          this.carregando = true
          const resposta = await pessoasApi.listarTelefones(this.pessoaId)
          this.telefones = resposta.data
        } catch (e) {
          console.error(e)
        } finally {
          this.carregando = false
        }
      },

      async salvar () {
        try {
          this.carregando = true
          const resposta = await pessoasApi.inserirTelefone({
            ...this.dados,
            pessoa_id: this.pessoaId,
          })
          this.dados = {}
          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') })
          return this.$emit('salvar', resposta.data)
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.formTelefones.setErrors(this.$erroApi.validacao(e))
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
          this.buscar()
        }
      },

      async deletar (telefone) {
        try {
          this.carregando = true
          await pessoasApi.deletarTelefone(telefone.id)
          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_deletar') })
        } catch (e) {
          console.log(e)
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
          this.buscar()
        }
      },

    },
  }
</script>

<style lang="sass">
  .v-input__append-outer
    margin-top: 0 !important
</style>
